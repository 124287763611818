import {Col, Form, Row} from "react-bootstrap";
import {Typeahead} from 'react-bootstrap-typeahead';
import '../../../../styles/Typeahead.css';
import styles from "./carga.module.css"
import React, {useContext, useEffect, useState} from "react";
import {ImMinus, ImPlus} from "react-icons/im";
import LangContext from "../../../../context/LangContext";
import DBContext from "../../../../context/DBContext";
import {onlyNumbers} from "../../../../config/functions";

const Especie = (prop) => {
    const {traduccion} = useContext(LangContext);
    const {especie} = useContext(DBContext);
    const [minFechaCos, setMinFechaCos] = useState(new Date(1776, 6, 4).toISOString().split('T')[0]);
    const [maxFechaEmbala, setMaxFechaEmbala] = useState(new Date().toISOString().split('T')[0]);
    const [percentCos, setPercentCos] = useState([]);
    const [percentEmb, setPercentEmb] = useState([]);
    const [sumaCajasEmb, setSumaCajasEmb] = useState(0);
    const [sumaCajasCos, setSumaCajasCos] = useState(0);

    const agregaFechaCosecha = () => {
        if (!prop.especie?.fruits) prop.especie.fruits.harvesting = [];
        prop.especie.fruits.harvesting.push({"harvestDate": "", "boxes": ""})
        //console.log(prop.especie)
        prop.setCarga([...prop.carga], prop.especie);
    }

    const quitarFechaCosecha = () => {
        prop.especie.fruits.harvesting.pop()
        const arr = percentCos
        arr.pop()
        setPercentCos([...arr])
        sumaCajasCosecha();
        setMinFechaCos(minFechaCosecha());
        prop.setErrors({...prop.errors, "cajasTotalFin": ""})
        prop.setCarga([...prop.carga], prop.especie);
    }
    const agregaFechaEmbalaje = () => {
        if (!prop.especie?.fruits.packaging) prop.especie.fruits.packaging = [];
        prop.especie.fruits.packaging.push({"packageDate": "", "boxes": ""})
        //console.log(prop.especie)
        prop.setCarga([...prop.carga], prop.especie);
    }
    const quitarFechaEmbalaje = () => {
        prop.especie.fruits.packaging.pop()
        const arr = percentEmb
        arr.pop()
        setPercentEmb([...arr])
        sumaCajasEmbalaje()
        maxFechaEmb();
        setMaxFechaEmbala(maxFechaEmb());
        prop.setErrors({...prop.errors, "cajasTotalFinE": ""})
        prop.setCarga([...prop.carga], prop.especie);
    }
    const soloSumaCajasCos = () => {
        let suma = 0;
        if (prop.especie.fruits.harvesting?.length > 0) {
            prop.especie.fruits.harvesting.forEach((item) => {
                suma += +item.boxes
            })
        }
        return suma
    }
    const sumaCajasCosecha = () => {
        let suma = soloSumaCajasCos();
        prop.setErrors({
            ...prop.errors,
            cajasTotal: suma > prop.especie.boxes ? "La suma de cajas de cosecha no puede ser mayor a la cantidad de cajas" : "",
        })
        setSumaCajasCos(suma)
        if (suma > prop.especie.boxes) {
            return "La suma de cajas de cosecha no puede ser mayor a la cantidad de cajas"
        } else {
            return ""
        }

    }
    const soloSumaCajasEmb = () => {
        let suma = 0;
        if (prop.especie.fruits.packaging?.length > 0) {
            prop.especie.fruits.packaging.forEach((item) => {
                suma += +item.boxes
            })
        }
        return suma
    }
    const sumaCajasEmbalaje = () => {
        let suma = soloSumaCajasEmb();
        prop.setErrors({
            ...prop.errors,
            cajasTotalE: suma > prop.especie.boxes ? "La suma de cajas de embalaje no puede ser mayor a la cantidad de cajas" : "",
        })
        setSumaCajasEmb(suma)
        if (suma > prop.especie.boxes) {
            return "La suma de cajas de embalaje no puede ser mayor a la cantidad de cajas"
        } else {
            return ""
        }
    }
    const minFechaCosecha = () => {
        let oldDate = new Date().toISOString().split('T')[0];
        //console.log(oldDate)
        //console.log(prop.especie.fruits.harvesting)
        prop.especie.fruits.harvesting.forEach((item) => {
            if (item.harvestDate < oldDate) {
                oldDate = item.harvestDate
                prop.setFechaHar(oldDate)
            }
        })
        // if (oldDate === new Date().toISOString().split('T')[0]) {
        //     oldDate = new Date(1776, 6, 4).toISOString().split('T')[0]
        // }
        return oldDate
    }
    const minFechaEmba = () => {
        let newDate = new Date().toISOString().split('T')[0];
        //console.log(newDate)
        prop.especie.fruits.packaging.forEach((item) => {
            if (item.packageDate < newDate) {
                newDate = item.packageDate
                // prop.setMinFechaEmb(oldDate)
            }
        })
        return newDate
    }
    const maxFechaEmb = () => {
        let oldDate = new Date(1776, 6, 4).toISOString().split('T')[0];
        //console.log(oldDate)
        prop.especie.fruits.packaging.forEach((item) => {
            if (item.packageDate > oldDate) {
                oldDate = item.packageDate
                prop.setFechaPack(oldDate)
            }
        })
        if (oldDate === new Date(1776, 6, 4).toISOString().split('T')[0]) {
            oldDate = new Date().toISOString().split('T')[0]
        }
        return oldDate
    }
    const porcentajeCosecha = () => {
        let porcentajes = [];
        prop.especie.fruits.harvesting.forEach((item) => {
            //console.log(item.boxes + "|%|" + prop.especie.boxes)
            let perc = +(item.boxes / prop.especie.boxes) * 100
            let perc2 = perc.toFixed(2)
            perc2 = perc2 || 0
            perc2 = isFinite(perc2) ? perc2 : "-"
            porcentajes.push(perc2)
        })
        setPercentCos(porcentajes)
        return (porcentajes)
    }
    const porcentajeEmbalaje = () => {
        let porcentajes = [];
        if (prop.especie.fruits.packaging.length > 0) {
            prop.especie.fruits.packaging.forEach((item) => {
                let perc = +(item.boxes / prop.especie.boxes) * 100
                let perc2 = perc.toFixed(2)
                porcentajes.push(perc2)
            })
        }
        setPercentEmb(porcentajes)
        return (porcentajes)
    }

    //verificar si los datos de un array son numeros y despues sumar los numeros de un array
    const isNumber = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }
    const sumarNumeros = (arr) => {
        if (arr.every(isNumber) && arr.length > 0) {
            return arr.reduce((a, b) => parseFloat(a) + parseFloat(b));
        }
    }

    const handleChange = (e) => {
        if (e !== undefined) {
            const id = e.target.value.split('|')[0];
            const fruitName = e.target.value.split('|')[1];
            if (e.target.name === "id") {
                prop.especie.id = id;
                prop.especie.name = fruitName;
                prop.setCarga([...prop.carga], prop.especie);
            }
            //Datos--------------------------
            if (e.target.name === "cajas") {
                prop.especie.boxes = parseInt(e.target.value);
                //console.log(prop.especie)
                prop.setCarga([...prop.carga], prop.especie);
                porcentajeCosecha();
                porcentajeEmbalaje();
            }
            if (e.target.name.slice(0, 7) === "cosecha") {
                if (e.target.name.slice(8, 13) === "cajas") {
                    prop.especie.fruits.harvesting[e.target.name.slice(-1)].boxes = e.target.value;
                    prop.setCarga([...prop.carga], prop.especie);
                    sumaCajasCosecha();
                    porcentajeCosecha();
                } else {
                    prop.especie.fruits.harvesting[e.target.name.slice(-1)].harvestDate = e.target.value;
                    setMinFechaCos(minFechaCosecha());
                }
            }
            if (e.target.name.slice(0, 7) === "embalaj") {
                const index = parseInt(e.target.name.match(/\d+$/)[0], 10); // Extrae el índice completo como número
                if (e.target.name.includes("cajas")) {
                    prop.especie.fruits.packaging[index].boxes = e.target.value;
                } else {
                    prop.especie.fruits.packaging[index].packageDate = e.target.value;
                }
                prop.setCarga([...prop.carga], prop.especie);
                sumaCajasEmbalaje();
                porcentajeEmbalaje();
            } else if (e.target.name.slice(0, 7) !== "cosecha" && e.target.name.slice(0, 7) !== "embalaj") {
                if (e.target.name !== "cajas") {
                    prop.especie[e.target.name] = e.target.value.split('|')[0];
                    prop.setCarga([...prop.carga], prop.especie);
                }

            }
        }
        //Validaciones--------------------------
        //console.log("---Validando Errores---")
        let errores = prop.errors;
        if (e !== undefined) {
            if (e.target.name === "cajas") {
                errores.cajas = e.target.value > 0 ? "" : "La cantidad de cajas debe ser mayor a 0";
            }
        }
        errores.cajasTotal = sumaCajasCosecha();
        errores.cajasTotalE = sumaCajasEmbalaje();
        prop.setErrors({...prop.errors, ...errores});
        prop.setCarga([...prop.carga], prop.especie);
        //console.log(prop.carga)
        //buscar el primer embalaje
        let firstPack = {}
        prop.carga.forEach((item) => {
            item.fruits.packaging.forEach((it) => {
                if (it.packageDate === minFechaEmba()) {
                    firstPack = it
                }
            })
        })

        if (prop.carga.length > 0) {
            prop.carga.forEach((item) => {
                //console.log(item.fruits)
                if (item.fruits.harvesting.length === 0) {
                    errores.cajasTotalFin = ""
                } else {
                    item.fruits.harvesting.forEach((it) => {
                        //console.log(it)
                        if (it.harvestDate === "") {
                            errores.cajasTotalFin = "La fecha de cosecha no puede estar vacia"
                        } else {
                            errores.cajasTotalFin = ""
                        }
                    })
                }
            })
        } else {
            errores.cajasTotalFin = ""
        }
        if (prop.carga.length > 0) {
            prop.carga.forEach((item) => {
                if (item.fruits.packaging.length === 0) {
                    errores.cajasTotalFinE = ""
                } else {
                    item.fruits.packaging.forEach((it) => {
                        //console.log(it)
                        if (it.packageDate === "") {
                            errores.cajasTotalFinE = "La fecha de embalaje no puede estar vacia"
                        } else {
                            errores.cajasTotalFinE = ""
                        }
                    })

                }
            })
        } else {
            errores.cajasTotalFinE = ""
        }
        let perCentCosecha = porcentajeCosecha()
        let perCentEmbalaje = porcentajeEmbalaje()

        if (soloSumaCajasCos() !== prop.especie.boxes) {
            if (parseInt(sumarNumeros(perCentCosecha)) !== 100 && (100 - parseInt(sumarNumeros(perCentCosecha))) > 0) {
                errores.cajasTotal = "Falta un " + (100 - parseInt(sumarNumeros(perCentCosecha))) + "% de cajas de cosecha";
            }
        }
        if (soloSumaCajasEmb() !== prop.especie.boxes) {
            if (parseInt(sumarNumeros(perCentEmbalaje)) !== 100 && (100 - parseInt(sumarNumeros(perCentEmbalaje))) > 0) {
                errores.cajasTotalE = "Falta un " + (100 - parseInt(sumarNumeros(perCentEmbalaje))) + "% de cajas de embalaje";
            }
        }
        //console.log(prop.errors.cajasTotal)
        prop.validate();
    }
    // useEffect(()=>{
    //     prop.setCarga([...prop.carga],prop.especie);
    // },[prop.especie])

    useEffect(() => {
        //console.log(prop.carga)
        setMinFechaCos(minFechaCos)
    }, [prop.carga])
    //console.log(prop.fechaHar)
    //console.log(prop.fechaPack)


    useEffect(() => {
        handleChange()
    }, [])


    return (
        <div className={styles.container}>
            <Row className={styles.row}>
                <Col className={styles.columns}>
                    <div className={styles.selects}>
                        <p className="pe-2">{traduccion?.carga?.especie}</p>
                        <Form.Group className="me-3" id="Especie">
                            {/*<Form.Control onChange={handleChange} type="text" placeholder="Especie"
                                          value={prop.especie?.especie_nombre} name="name"/>*/}
                            <Form.Select name="id" onChange={handleChange} style={{minWidth: '110px'}}>
                                <option value="">---</option>
                                {
                                    especie.map(item => {
                                        return (
                                            <option
                                                selected={prop.especie?.name === item.description}
                                                key={item.key}
                                                value={`${item.id}|${item.description}`}
                                            >
                                                {item.description}
                                            </option>
                                        );
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className={styles.selects_mini}>
                        <p className="pe-2">{traduccion?.carga?.cajas}</p>
                        <Form.Group className={styles.selects_input} id="especie_cantidad_cajas">
                            <Form.Control type="text" min={1}
                                          style={{marginBottom: '0.5rem'}}
                                          onChange={handleChange} placeholder={traduccion?.mantendores?.cajas}
                                          onInput={onlyNumbers}
                                          value={prop.especie?.boxes} name="cajas"/>
                        </Form.Group>
                        {prop.errors.cajas &&
                            <span className={styles.errors} style={{marginLeft: -17}}>{prop.errors.cajas}</span>}
                    </div>
                </Col>


                <Col className={styles.columns} style={{minWidth: 'fit-content', justifyContent: 'end'}}>
                    <div>
                        <Form.Group className="me-3" id="fecha">
                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                <div className={styles.selects_mini} style={{marginRight: '1rem'}}>
                                    <p className="pe-2">{traduccion.carga?.fechaCosecha}</p>
                                    {(prop.especie.fruits?.harvesting?.length)
                                        ? prop.especie.fruits?.harvesting.map((datos, idx) =>
                                            <Form.Control key={idx}
                                                          type="date"
                                                          className={styles.selectsin}
                                                          style={{marginBottom: '0.5rem'}}
                                                          max={maxFechaEmbala}
                                                          value={datos.harvestDate?.split('T')[0]}
                                                          onChange={handleChange}
                                                          name={`cosecha_${idx}`}
                                                          placeholder={traduccion?.mantendores?.fecha}
                                            />)
                                        : <p style={{textAlign: "center"}}>{traduccion.carga?.sinFechaCosecha}</p>
                                    }
                                </div>

                                <div>
                                    {(prop.especie.fruits?.harvesting?.length) ?
                                        <div className={styles.selects_mini}>
                                            <p className="pe-2">{traduccion.carga?.cajas}</p>
                                            <Form.Group className={styles.selects_input} id="ExporterName">
                                                {(prop.especie.fruits.harvesting?.length) ?
                                                    prop.especie.fruits.harvesting.map((datos, idx) =>
                                                        <Form.Control key={idx} type="text"
                                                                      min={1}
                                                                      style={{marginBottom: '0.5rem'}}
                                                                      onChange={handleChange}
                                                                      onInput={onlyNumbers}
                                                                      name={`cosecha_cajas_${idx}`}
                                                                      placeholder={traduccion?.mantendores?.cajas}
                                                                      value={datos.boxes}/>
                                                    )
                                                    : ""
                                                }
                                            </Form.Group>
                                        </div> : ""

                                    }

                                </div>
                                <div className={styles.selects_mini}>
                                    <p className="pe-2">{traduccion.carga?.porcentaje}</p>
                                    {
                                        percentCos.map((datos, idx) =>
                                            <p key={idx} className={styles.selects_input}
                                               style={{padding: '.3rem 0'}}>{datos}%</p>)
                                    }

                                </div>

                                {/*{(parseInt(sumarNumeros(percentCos)) !== 100 && (100 - parseInt(sumarNumeros(percentCos))) > 0) &&*/}
                                {/*    <p className={styles.errors}> Falta*/}
                                {/*        un {(100 - (sumarNumeros(percentCos))).toFixed(2)}% de cajas</p>*/}
                                {/*}*/}
                            </div>
                            {prop.errors.cajasTotal &&
                                <div className={styles.errors}
                                     style={{marginLeft: 0, width: '350px'}}>{prop.errors.cajasTotal}</div>
                            }

                            <div className={styles.add_fecha}>
                                <span>{traduccion.carga?.agregarFecha}</span>
                                {(prop.especie.fruits?.harvesting.length) ?
                                    <span onClick={quitarFechaCosecha}
                                          className={styles.add_fecha_plus}><ImMinus/></span>
                                    : <span className={styles.add_fecha_no}><ImMinus/></span>
                                }
                                <span onClick={() => agregaFechaCosecha()}
                                      className={styles.add_fecha_plus}><ImPlus/></span>
                            </div>

                        </Form.Group>


                    </div>


                </Col>
                <Col className={styles.columns} style={{minWidth: 'fit-content', justifyContent: 'end'}}>
                    <div>
                        <Form.Group className="me-3" id="Especie">
                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                <div className={styles.selects_mini} style={{marginRight: '1rem'}}>
                                    <p className="pe-2">{traduccion.carga?.fechaEmbalaje}</p>
                                    {(prop.especie.fruits?.packaging?.length)
                                        ? prop.especie.fruits.packaging.map((datos, idx) =>
                                            <Form.Control key={idx}
                                                          type="date"
                                                          min={minFechaCos}
                                                          className={styles.selectsin}
                                                          style={{marginBottom: '0.5rem'}}
                                                          value={datos.packageDate?.split('T')[0]}
                                                          max={new Date().toISOString().split("T")[0]}
                                                          onChange={handleChange}
                                                          name={`embalaje_${idx}`}
                                                          placeholder={traduccion?.mantendores?.fecha}/>)
                                        : <p style={{textAlign: "center"}}>{traduccion.carga?.sinFechaEmbalaje}</p>
                                    }
                                </div>
                                <div>
                                    {(prop.especie.fruits?.packaging?.length) ?
                                        <div className={styles.selects_mini}>
                                            <p className="pe-2">{traduccion.carga?.cajas}</p>
                                            <Form.Group className={styles.selects_input} id="ExporterName">
                                                {(prop.especie.fruits.packaging?.length)
                                                    ? prop.especie.fruits.packaging.map((datos, idx) =>
                                                        <Form.Control key={idx} type="text"
                                                                      min={1}
                                                                      onChange={handleChange}
                                                                      onInput={onlyNumbers}
                                                                      style={{marginBottom: '0.5rem'}}
                                                                      placeholder={traduccion?.mantendores?.cajas}
                                                                      name={`embalaje_cajas_${idx}`}
                                                                      value={datos.boxes}/>)
                                                    : ""
                                                }

                                            </Form.Group>
                                        </div>
                                        : ""
                                    }
                                </div>
                                <div className={styles.selects_mini}>
                                    <p className="pe-2">{traduccion.carga?.porcentaje}</p>
                                    {
                                        percentEmb.map((datos, idx) =>
                                            <p key={idx} className={styles.selects_input}
                                               style={{marginBottom: '1.5rem'}}>{datos}%</p>)
                                    }
                                </div>
                                {/*{(parseInt(sumarNumeros(percentEmb)) !== 100 && (100 - parseInt(sumarNumeros(percentEmb))) > 0) &&*/}
                                {/*    <p className={styles.errors}> Falta*/}
                                {/*        un {(100 - (sumarNumeros(percentEmb))).toFixed(2)}% de cajas</p>*/}
                                {/*}*/}


                            </div>
                            {prop.errors.cajasTotalE &&
                                <div className={styles.errors}
                                     style={{marginLeft: 0, maxWidth: '350px'}}>{prop.errors.cajasTotalE}</div>
                            }
                            <div className={styles.add_fecha}>
                                <span>{traduccion.carga?.agregarFecha}</span>
                                {(prop.especie.fruits?.packaging?.length) ?
                                    <span onClick={quitarFechaEmbalaje}
                                          className={styles.add_fecha_plus}><ImMinus/></span>
                                    : <span className={styles.add_fecha_no}><ImMinus/></span>
                                }
                                <span onClick={() => agregaFechaEmbalaje()} className={styles.add_fecha_plus}><ImPlus/></span>
                            </div>
                        </Form.Group>

                    </div>

                </Col>
            </Row>
        </div>
    )
}


export const CargaTab = ({
                             imRsk, setFechaPack, fechaPack, fechaHar,
                             setFechaHar, setClaimForm, claimForm,
                             stuff, errors, setErrors, transporte,
                             validate
                         }) => {
    // const {stuffing} = useContext(AccountContext);
    const {currency, damage} = useContext(DBContext);
    const {traduccion} = useContext(LangContext);


    // Aca empieza la carga ----------------------------------------------
    const [carga, setCarga] = useState([]);
    const [stuffing, setStuffing] = useState({carga})
    //const [cargo, setCargo] = useState(claimForm?.cargo);

    // se descontruye objeto cargo y se saca el valor de reclaimedAmount porque este se setea en 2 componentes mas:
    // Transporter Martirimo y Terrestre, luego cuando se setean los otros valores del cargo se pasa de nuevo el valor
    // que viene en reclaimedAmount desde los otros ocmponentes.
    const { reclaimedAmount, ...cargoWithoutReclaimedAmount } = claimForm?.cargo;
    const [cargo, setCargo] = useState(cargoWithoutReclaimedAmount);
    const [damages, setDamages] = useState(claimForm?.cargo.damages);
    const [generalDamage, setGeneralDamage] = useState(!imRsk ? damage : damage.filter(d => d.damage.id === 3) || []);

    //console.log(cargo)

    useEffect(() => {
        if (stuff !== null) {
            // //console.log(stuff.especies);
            setCarga(null)
        }
    }, [stuff]);

    let especie = {id: "", name: "", boxes: "", fruits: {harvesting: [], packaging: []}};

    //console.log(carga);
    //console.log(cargo)

    const agregaEspecie = () => {
        let obj = {...cargo};
        obj.fruits.push(especie);
        setCargo(obj)
    }
    const quitarEspecie = () => {
        let obj = {...cargo};
        obj.fruits.pop()
        setCargo(obj)
    }

    useEffect(() => {
        setStuffing({...stuffing, carga})
    }, [carga]);

    const handleChange = (e) => {
        let value
        switch (e.target.name) {
            case "currency":
            case "reclaimedAmount":
            case "temperature":
            case "ventilation":
                value = e.target.value;
                break;
            default:
                value = e.target.value;
        }
        let ids = [];
        damages?.map(item => {
            ids.push(item.id)
        })
        setCargo({
            ...cargo,
            [e.target.name]: value,
            reclaimedAmount: reclaimedAmount,
            damagesId: ids,
            damages: damages
        })
    }

    useEffect(() => {
        setDamages(cargo?.damages)
    }, [])

    useEffect(() => {
        setClaimForm({...claimForm, cargo})
    }, [cargo]);

    const handleCheck = (e) => {
        setCargo({
            ...cargo,
            [e.target.name]: e.target.checked
        })

    }


    useEffect(() => {
        //add subclaim claimForm
        let ids = [];
        //console.log(damages)
        let form = claimForm;

        damages?.map(item => {
            ids.push(item.id)
        })
        setCargo({
            ...cargo,
            damagesId: ids,
            damages: damages
        })
        if (form?.cargo) {
            form.cargo.damagesId = ids;
            form.cargo.damages = damages;
        }
        setCargo(cargo)
        setClaimForm(form)

        //console.log(claimForm)
        validate()
    }, [damages]);


    //vigilando cada vez que se modifica el claim


    return (
        <div>
            <>
                {
                    transporte !== 2 &&
                    <div className={styles.title_carga}>{traduccion.carga?.datosContenedor}</div>
                }

                <Row>
                    {transporte !== 2 &&
                        <>
                            {/*este es el código html para NUMERO CONTENEDOR*/}
                            <Col className={styles.columns}>
                                <div className={styles.tipo_danio}>
                                    <div className={styles.selects}>
                                        <p className={styles.selects_p}>{transporte === 3 ? traduccion.carga?.placaPatente : traduccion.carga?.nContenedor}</p>
                                        <Form.Group className={styles.selects_input} id="containerNumber">
                                            <Form.Control type="text" name="containerNumber" onChange={handleChange}
                                                          value={cargo?.containerNumber}/>
                                        </Form.Group>
                                    </div>
                                </div>
                            </Col>

                            {/*este es el código html para TEMPERATURA*/}
                            <Col className={styles.columns}>
                                <div className={styles.tipo_danio}>
                                    <div className={styles.selects}>
                                        <p className={styles.selects_p}>{traduccion.carga?.temperatura}</p>
                                        <Form.Group className={styles.selects_input} id="temperature">
                                            <Form.Control type="text" name="temperature"
                                                          value={cargo?.temperature}
                                                          onChange={handleChange}
                                                          onInput={onlyNumbers}/>
                                        </Form.Group>
                                    </div>
                                </div>
                            </Col>

                            {/*este es el código html para VENTILACION*/}
                            <Col className={styles.columns}>
                                <div className={styles.tipo_danio}>
                                    <div className={styles.selects}>
                                        <p className={styles.selects_p}>{traduccion.carga?.ventilacion}</p>
                                        <Form.Group className={styles.selects_input} id="ventilation">
                                            <Form.Control type="text" name="ventilation" onChange={handleChange}
                                                          value={cargo?.ventilation}
                                                          onInput={onlyNumbers}/>
                                        </Form.Group>
                                    </div>
                                </div>
                            </Col>
                        </>
                    }
                    {transporte === 1 &&
                        <Col className={styles.columns}>
                            {/*este es el código html para O2, CO2 Y TRATAMIENTO FRIO*/}
                            <div className={styles.tipo_danio}>
                                <div style={{display: 'flex'}}>
                                    <div>
                                        <p className={styles.selects_p}>{traduccion.carga?.ca}</p>
                                        <Form.Check
                                            style={{margin: "0 .6rem", width: 10, height: 10}}
                                            inline
                                            label=""
                                            name="ca"
                                            type="checkbox"
                                            id="inline-checkbox-2"
                                            checked={cargo?.ca}
                                            onChange={handleCheck}
                                        />
                                    </div>

                                    {/*este es el código html los input de O2 Y CO2*/}
                                    <div style={{paddingLeft: '2rem'}}>
                                        {cargo?.ca &&
                                            <Form.Group className={styles.selects_input} id="o2"
                                                        style={{maxWidth: '7ch'}}>
                                                <div>O2</div>
                                                <div style={{display: 'flex'}}>
                                                    <Form.Control type="text" name="o2" min={0} max={21}
                                                                  onChange={handleChange}
                                                                  onInput={onlyNumbers}
                                                                  value={cargo?.o2}/> <span
                                                    style={{alignSelf: 'center', paddingLeft: '.2rem'}}>%</span>
                                                </div>
                                            </Form.Group>

                                        }
                                        {cargo?.ca &&
                                            <Form.Group className={styles.selects_input} id="co2"
                                                        style={{maxWidth: '7ch', marginTop: '1rem'}}>
                                                <div>CO2</div>
                                                <div style={{display: 'flex'}}>
                                                    <Form.Control type="text" name="co2" min={0} max={21}
                                                                  onChange={handleChange}
                                                                  onInput={onlyNumbers}
                                                                  value={cargo?.co2}/><span
                                                    style={{alignSelf: 'center', paddingLeft: '.2rem'}}>%</span>
                                                </div>
                                            </Form.Group>
                                        }
                                    </div>
                                </div>
                                {/*este es el código html para TRATAMIENTO FRIO*/}
                                <p className={styles.selects_p}
                                   style={{marginTop: '1rem'}}>{traduccion.carga?.preEnfriado}</p>
                                <Form.Check
                                    style={{margin: "0 .6rem", width: 10, height: 10}}
                                    inline
                                    label=""
                                    name="coldTreatment"
                                    type="checkbox"
                                    id="inline-checkbox-2"
                                    checked={cargo?.coldTreatment}
                                    onChange={handleCheck}
                                />
                            </div>
                        </Col>
                    }
                </Row>
            </>
            {transporte !== 2 ?
                <div className={styles.title_carga2}>{traduccion.carga?.cargas}</div>
                :
                <div className={styles.title_carga2} style={{marginTop: '1.3rem'}}>{traduccion.carga?.cargas}</div>
            }

            {/*<Especie especie={especie} carga={carga} setCarga={setCarga}/>*/}
            {
                cargo?.fruits?.map((datos, idx) => {
                    return (
                        <Especie key={idx}
                                 fechaHar={fechaHar} fechaPack={fechaPack}
                                 setFechaHar={setFechaHar} setFechaPack={setFechaPack}
                                 especie={datos} carga={cargo?.fruits} setCarga={setCarga} validate={validate}
                                 errors={errors} setErrors={setErrors}/>
                    );
                })
            }
            {/*este es el código html para AGREGAR O QUITAR ESPECIES DE FRUTAS*/}
            <div className={styles.add_especie}>
                <span>{cargo?.fruits?.length ? `${traduccion.carga?.agregarEspecie}` : `${traduccion.carga?.addEspecie}`}</span>
                {cargo?.fruits?.length ?
                    <span onClick={quitarEspecie} className={styles.add_fecha_plus}><ImMinus/></span>
                    : <span className={styles.add_fecha_no}><ImMinus/></span>
                }
                <span onClick={agregaEspecie} className={styles.add_fecha_plus}><ImPlus/></span>
            </div>

            {/*este es el código html para TIPO DE DAÑO*/}
            <div className={styles.container}>
                <Row className={styles.row}>
                    <Col className={styles.columns}>
                        <div className={styles.tipo_danio}>
                            <div className={styles.titles}>{traduccion.carga?.tipoDanio}</div>
                            <div className={styles.selects}>
                                <p className={styles.selects_p}>{traduccion.carga?.tipoDanio}</p>
                                <Typeahead
                                    id="basic-typeahead-multiple"
                                    multiple
                                    labelKey="description"
                                    onChange={setDamages}
                                    options={generalDamage}
                                    selected={damages}
                                />
                            </div>

                            <div className={styles.selects}>
                                <p className={styles.selects_p}>{traduccion.carga?.etd}</p>
                                <Form.Group className={styles.selects_input} id="ETD">
                                    <Form.Control type="date" name="etd" onChange={handleChange}
                                                  value={cargo?.etd?.substring(0, 10)}
                                                  max={cargo?.eta?.substring(0, 10)}/>
                                </Form.Group>
                                <p className={styles.selects_p}>{traduccion.carga?.eta}</p>
                                <Form.Group className={styles.selects_input} id="ETA">


                                    <Form.Control type="date" name="eta" onChange={handleChange}
                                                  className={styles.selects_input}
                                                  min={cargo?.etd?.substring(0, 10)}
                                                  value={cargo?.eta?.substring(0, 10)}/>
                                </Form.Group>
                            </div>
                        </div>
                    </Col>


                    {/*<Col className={styles.columns} style={{gap: '1rem', display: "flex", flexDirection: 'column'}}>*/}
                    {/*    <div className={styles.titles}>{traduccion.carga?.montoReclamado}</div>*/}
                    {/*    <div style={{display: 'flex'}}>*/}

                    {/*        /!*<div className={styles.selects} style={{marginRight: '1rem'}}>*!/*/}
                    {/*        /!*    <p className="pe-2">{traduccion.carga?.moneda}</p>*!/*/}
                    {/*        /!*    <Form.Group className={styles.selects_input} id="moneda">*!/*/}
                    {/*        /!*        <Form.Select disabled={!imRsk} className={imRsk? styles.selects_input:styles.selects_input_disabled} name="currency" onChange={handleChange} value={cargo?.currency}>*!/*/}
                    {/*        /!*            /!*    {claimForm.currency&& claimForm.currency.map((moneda, index) => (*!/*!/*/}
                    {/*        /!*            /!*        <option key={index} value={moneda}>{moneda}</option>*!/*!/*/}
                    {/*        /!*            /!*    ))}*!/*!/*/}
                    {/*        /!*            /!*</Form.Select>*!/*!/*/}
                    {/*        /!*            <option value="">---</option>*!/*/}
                    {/*        /!*            {*!/*/}
                    {/*        /!*                currency.map((currency,idx) => (*!/*/}
                    {/*        /!*                        <option key={idx} value={currency.id}>*!/*/}
                    {/*        /!*                            {currency.description}*!/*/}
                    {/*        /!*                        </option>*!/*/}
                    {/*        /!*                    )*!/*/}
                    {/*        /!*                )*!/*/}
                    {/*        /!*            }*!/*/}
                    {/*        /!*        </Form.Select>*!/*/}
                    {/*        /!*    </Form.Group>*!/*/}
                    {/*        /!*</div>*!/*/}
                    {/*        <div className={styles.selects}>*/}
                    {/*            <p className="pe-2">{traduccion.carga?.monto}</p>*/}
                    {/*            <Form.Group className={!imRsk ?styles.selects_input : styles.selectdis} id="montoReclamo">*/}
                    {/*                <Form.Control type="text" disabled={!imRsk}    placeholder="0" name="reclaimedAmount"*/}
                    {/*                              onChange={handleChange} value={cargo?.reclaimedAmount}/>*/}
                    {/*            </Form.Group>*/}
                    {/*        </div>*/}

                    {/*    </div>*/}
                    {/*</Col>*/}
                    {
                        claimForm?.cargo?.damagesId?.includes(3) ?
                            <div className={styles.nota}>
                                Nota: Si reclama por retraso deberá incorporar
                                booking de la naviera/cia. aerea emisora del documento con ETA al destino ofrecido.
                                Si no lo incorpora es posible que su caso no posea los meritos suficientes para un
                                reclamo de carga
                            </div> : null
                    }
                </Row>
            </div>
        </div>
    )
}
