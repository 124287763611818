import {Button, Form, Modal, Spinner} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import styles from "./refnumbers.module.css"
import LangContext from "../../context/LangContext";
import axios from "axios";
import {urlConfig} from "../../config/urlConfig";
import {toast} from "react-toastify";
// import { useLayoutEffect } from "react";
export const RefNumbersAndStatus = ({refNumber, claimForm, setClaimForm, imRsk}) => {
    const {traduccion} = useContext(LangContext);
    // const {statusClaim} = useContext(DBContext);
    const [claimStatus, setClaimStatus] = useState(claimForm?.claimStatus?.id || "");
    const [refNumbers, setRefNumbers] = useState([{
        number: "",
        name: ""
    }]);
    const [isEnabledAddRefButton, setIsEnabledAddRefButton] = useState(true);
    const [show2, setShow2] = useState(false);
    const [statusToModal, setStatusToModal] = useState(0);
    const [loading, setLoading] = useState(false);

    const [statusCl, setStatusCl] = useState([
        {
            id: 1,
            name: "New"
        },
        {
            id: 2,
            name: "Survey Report"
        },
        {
            id: 3,
            name: "Pending documents"
        },
        {
            id: 4,
            name: "Ready to Claim"
        },
        {
            id: 5,
            name: "Closed"
        },
        {
            id: 6,
            name: "Out of Court"
        },
        {
            id: 7,
            name: "In court"
        },
        {
            id: 8,
            name: "Recovered"
        },
        {
            id: 9,
            name: "Not Recovered"
        },
        {
            id: 10,
            name: "Signed release"
        }
    ]);
    let token = localStorage.getItem('token');
    let tokenAtob = atob(token.split('.')[1]);
    let tokenJson = JSON.parse(tokenAtob);
    useEffect(() => {
        //console.log(tokenJson)
        if (tokenJson.rol !== "ROLE_EXPORTER") {
            let statysss = [];
            let permisos = tokenJson.permissions.split(',').map(Number);
            if (!permisos.includes(4)) {
                permisos.push(4);
            }
            //console.log(permisos)
            permisos.sort((a, b) => a - b);

            statusCl.map((obj) => {
                //console.log("obj", obj)
                for (let permiso of permisos) {
                    //console.log("permiso", permiso)
                    if (permiso == obj.id) {
                        statysss.push(obj)
                    }
                }
            })
            setStatusCl(statysss)
            //console.log("statysss", statysss)
        }
    }, [])

    const maxRefNumbers = 5;

    const handleAddRefButton = () => {
        const newRefNumbers = [...refNumbers, {
            number: null,
            name: null
        }]
        setRefNumbers(newRefNumbers)
        newRefNumbers.length < maxRefNumbers || setIsEnabledAddRefButton(false);
    }

    const handleRemoveRefButton = (index) => {
        const newRefNumbers = [...refNumbers];
        newRefNumbers.splice(index, 1);
        setRefNumbers(newRefNumbers);
        isEnabledAddRefButton || setIsEnabledAddRefButton(true);
    }

    const handleRefInput = (e) => {
        // const arrRef = claimForm.ref;
        const newRefNumbers = [...refNumbers];
        const {value, id: index, name} = e.target
        //console.log({newRefNumbers})
        //console.log(e.target.style.width, e.target.value.length, 10)
        name === 'code' ? newRefNumbers[index].number = value : newRefNumbers[index].name = value;
        setRefNumbers(newRefNumbers);
        e.target.style.width = e.target.value.length > 12 ? e.target.value.length + 2 + "ch" : "12ch";

    }
    useEffect(() => {
        //console.log({refNumbers})
        if (claimForm?.referenceNumbers) {
            let numerosRef = [];
            for (let i = 0; i < 5; i++) {
                if (claimForm.referenceNumbers[`refNumber${i}`] !== null && claimForm.referenceNumbers[`refNumber${i}`] !== undefined && claimForm.referenceNumbers[`refNumber${i}`] !== "") {
                    let numero = claimForm.referenceNumbers[`refNumber${i}`].split("/")[0]
                    let resto = claimForm.referenceNumbers[`refNumber${i}`].split("/")[1]
                    numerosRef.push({
                        number: numero,
                        name: resto
                    })

                }
            }

            setRefNumbers(numerosRef);
        }
    }, []);

    const typing = (e) => {
        //console.log({length: e.target.value.length})
        e.target.setAttribute('size', e.target.value.length);
    }
    const myInput = document.querySelector('.resize');
    myInput?.addEventListener('Form.Control', typing);
    useEffect(() => {
        //console.log(refNumbers)
        let referenceNum = {};

        for (let i = 0; i < refNumbers.length; i++) {
            referenceNum[`refNumber${i + 1}`] = `${refNumbers[i].number}/${refNumbers[i].name}`;
        }

        setClaimForm({
            ...claimForm,
            referenceNumbers: referenceNum
        })
    }, [refNumbers]);
    //console.log({refNumbers})
    let statusArrayName = Object.values(traduccion?.topbar);
    let thisStatus = statusArrayName[claimForm?.lastTimelineId] || statusArrayName[10];

    const abrirModal = (e) => {
        setShow2(true);
        setStatusToModal(e.target.value);
    }
    const changeStatusClaim = (idStatus) => {
        setLoading(true);

        axios.post(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/status/${claimForm.id}/`,
            {
                id: idStatus,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token').replace("Bearer ", "")}`
                }
            }).then(res => {
            toast.success("Status changed successfully")
            //console.log(res)
            setLoading(false);
            setShow2(false);
            setClaimStatus(statusToModal)

        }).catch(err => {
            toast.error("Error changing status")
            //console.log(err)
            setLoading(false);
            setShow2(false);
        })
    }

    return (
        <>
            {!claimForm?.hasMap &&
                <div style={{background: 'white', color: 'red', borderBottom: '1px dotted red'}}> Recovery Only </div>
            }
            <div className={styles.barra_numeros}>
                <div className={styles.numeros}>
                    <div>
                        <p>Ref.RSK</p>
                        <p className={styles.negrita_numeros}>{refNumber}</p>
                    </div>
                    <div>
                        <p>Ref. </p>
                        {
                            refNumbers.map((ref, index) => {
                                // //console.log({ref})
                                return (
                                    <div className={styles.refnumbers} key={index}>
                                        <Form.Group style={{width: '35ch'}} className={styles.refnumbers}>
                                            <Form.Control className={styles.inputrefnumbers} type="text"
                                                          style={{width: '12ch'}}
                                                          placeholder="code" id={`${index}`} name="code"
                                                          onChange={handleRefInput}
                                                          value={ref.number === "null" ? "": ref.number}/>
                                            <Form.Control className={styles.inputrefnumbers} type={ref.type}
                                                          style={{width: '12ch'}}
                                                          placeholder={traduccion?.mantendores?.nombre} id={`${index}`}
                                                          name="nameref" onChange={handleRefInput}
                                                          value={ref.name === "null"?"" : ref.name}/>
                                            {
                                                index ?
                                                    <button className={styles.input_buttonremove} key={index} id={index}
                                                            onClick={() => handleRemoveRefButton(index)}>remove</button>
                                                    : null
                                            }
                                        </Form.Group>

                                    </div>
                                )
                            })
                        }
                    </div>
                    <div style={{alignItems: 'center', display: 'flex'}}>
                        <button className={styles.input_buttonadd} name="addref" onClick={handleAddRefButton}
                                disabled={!isEnabledAddRefButton}>+
                        </button>
                    </div>
                </div>
                <div className={styles.status}>
                    <Form.Select className={styles.select}
                                 style={{fontWeight: '600'}}
                                 disabled={!imRsk}
                                 value={claimStatus} aria-label="Default select example"
                                 onChange={abrirModal}>
                        {statusCl.map((status, index) => {
                            return (
                                <option key={index} value={status.id}>{status.name}</option>
                            )
                        })}
                    </Form.Select>
                    {/*<div className={styles.status2}>*/}
                    {/*    <p>{thisStatus}</p>*/}
                    {/*</div>*/}
                </div>
            </div>
            <Modal
                show={show2} onHide={() => setShow2(false)} size="lg" centered>
                <Modal.Header closeButton={!loading}>
                    <Modal.Title style={{fontWeight: 'bolder'}}>{traduccion?.others?.deseaCambiarEstado}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <p style={{textAlign: "center", margin: '1rem'}}>{traduccion?.others?.rUsure2}
                        <b>{statusCl?.find(p => p.id == statusToModal)?.name}</b>?</p>
                    <div style={{display: 'flex', justifyContent: 'space-around', marginTop: '2rem'}}>
                        <Button disabled={loading} variant="warning" onClick={() => setShow2(false)}>
                            {traduccion?.others?.close}

                        </Button>
                        <Button disabled={loading} variant={"primary"} style={{marginLeft: '1rem'}}
                                onClick={() => changeStatusClaim(statusToModal)}>
                            {loading && <Spinner/>}
                            {!loading && traduccion?.others?.Accept}
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
