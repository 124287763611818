import React, { useContext } from "react";
import styles from "../surveyors/mantenedor_surveyor.module.css";
import { Link } from "react-router-dom";
import LangContext from "../../../context/LangContext";

export const Dashboard = ({setRouteActive}) => {
    const goToStart = () => {
        setRouteActive("/");
    };
    const {traduccion} = useContext(LangContext);


    return (
        <>
            <div className={styles.all_surveyor} style={{ width: "100%" }}>
                <div className={styles.breadcrumb}>
                    {traduccion?.breadcrumb?.uRin}
                    <Link className={styles.like_a} to={"/"}>
                        <span onClick={goToStart}>{traduccion?.breadcrumb?.home}</span>
                    </Link>
                    &gt;
                    <span className={styles.bold}>Dashboard</span>
                </div>
                <div className={styles.title_surveyor}>
                    <p>Dashboard</p>
                </div>
                <div
                    className={styles.dashboard}
                    style={{
                        width: "100%",
                        height: "300vh", // Ajusta esta altura según lo que necesites
                        overflow: "hidden"
                    }}
                >
                    <iframe
                        title="Dashboard Metabase"
                        className={styles.grafico}
                        style={{
                            width: "100%",
                            height: "100%",
                            border: "none",
                        }}
                        src="https://rsk-chile.metabaseapp.com/public/dashboard/c5cdef92-5b53-4de2-bdc9-51c5be1c62aa"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        </>
    );
};
