import styles from "./maritimo.module.css";
import {FaPlaneDeparture} from "react-icons/fa";
import {GiAirplaneArrival, GiAirplaneDeparture} from "react-icons/gi";
import {Col, Container, Form, Row} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import React, {useContext, useEffect, useRef, useState} from "react";
import AccountContext from "../../../context/DBContext";
import airports from "../../../data/airports.json";
import DBContext from "../../../context/DBContext";
import {TbArrowNarrowLeft, TbArrowNarrowRight, TbLineDotted} from "react-icons/tb";
import useWindowSize from "../../../hooks/useWindowSize";
import axios from "axios";
import LangContext from "../../../context/LangContext";
import {urlConfig} from "../../../config/urlConfig";
import{onlyNumbers} from "../../../config/functions";

export const TransporteAereo = ({claimForm,setClaimForm,stuff,setStuff,imRsk,setExportersName,exportersName,editRsk, handleChangeExporter}) => {
    const {stuffing} = useContext(AccountContext);
    const{currency} = useContext(DBContext);
    //console.log({stuffing})
    const {height, width} = useWindowSize();
    const [awbList, setAwbList] = React.useState([]);
    const [aerolinea, setAerolinea] = React.useState("");
    const [awbNum, setAwbNum] = React.useState("");
    const [awb2, setAwb2] = React.useState("");
    const [errorAwb, setErrorAwb] = React.useState(false);
    const {traduccion} = useContext(LangContext);
    const [receiverList, setReceiverList] = useState([]);
    const [listForInput, setListForInput] = useState([]);

    useEffect(() => {
        //console.log("SETEANDO EL AWB")
        setClaimForm({...claimForm, awb:claimForm.awb.split("-")[0],awb2:claimForm.awb.split("-")[1]})
    },[]);

    const refAWB1 = useRef("");
    const refAWB2 = useRef("");
    const handleChange = (e) => {
        const opciones = e.target.options;
        const valorSeleccionado = e.target.value;
        let textoSeleccionado;
        let value;
        switch (e.target.name) {
            case "currencyId":
                value = parseInt(e.target.value);
                break;
            case "currency":
                value = parseInt(e.target.value);
                break;
            default:
                value = e.target.value;
        }

        if (e.target.name === "ata") {
            setClaimForm({...claimForm,
                ata:e.target.value,
                dischargeDate: e.target.value,
            })
        }

        if (e.target.name === "awb") {
            // //console.log("AWB1")
            setAwbNum(e.target.value)
            setClaimForm({...claimForm, awb: e.target.value + "-" + refAWB2.current.value,
                                        blAwb: e.target.value+ "-" + refAWB2.current.value})
        }else{
            // //console.log("ELSE")
            setClaimForm({
                ...claimForm,
                [e.target.name]: e.target.value
            })
        }

        if (e.target.name === "awb2") {
            setClaimForm({...claimForm, awb: refAWB1.current.value + "-" + e.target.value,
                                        blAwb: refAWB1.current.value + "-" + e.target.value})
        }
        //change claim on sessionStorage
        //sessionStorage.setItem("claim", JSON.stringify(claimForm))

        if (e.target.name === "reclaimedAmount"){

            let addRecoveredCargo = claimForm?.cargo;
            addRecoveredCargo = {
                ...addRecoveredCargo,
                reclaimedAmount:e.target.value
            };
            setClaimForm({
                ...claimForm,
                cargo:addRecoveredCargo
            })
        }
        if(e.target.name === "currency") {
            let addRecoveredCargo = claimForm?.cargo;
            addRecoveredCargo = {
                ...addRecoveredCargo,
                currency: e.target.value
            };
            setClaimForm({
                ...claimForm,
                cargo: addRecoveredCargo
            })
        }
        if (e.target.name === "companyName") {
            //console.log("CAMBIO COMPANY NAME AEREO")
            for (let i = 0; i < opciones.length; i++) {
                if (opciones[i].value === valorSeleccionado) {
                    textoSeleccionado = opciones[i].text;
                    //console.log(textoSeleccionado);
                }
            }
            //console.log(textoSeleccionado)
            //console.log(value)
            setClaimForm({
                ...claimForm,
                [e.target.name]: textoSeleccionado,
                exporterName: textoSeleccionado,
                companyId: value
            })
        }
    }


    // const handleChangeAwbCode = (e) => {
    //
    //     axios.get(`https://airlabs.co/api/v9/airlines?iata_prefix=${e}&api_key=c447f4be-bd2b-4572-b0b9-7281d3b13eab`)
    //         .then((response) => {
    //             //console.log(response.data.response)
    //             setAwbList(response.data.response)
    //         }).catch((error) => {
    //         //console.log(error);
    //         })
    // }
    // const changeAirline = (e) => {
    //     if (e.length > 0) {
    //         setClaimForm({
    //             ...claimForm,
    //             awb: e[0].iata_code,
    //             vessel: e[0].name
    //         })`
    //     }
    // }
    const handleChangePol = (e) => {
        //console.log(e)
        if (e.length > 0) {
            setClaimForm({
                ...claimForm,
                pol: e[0]
            })
        }
    }
    const handleChangePod = (e) => {
        if (e.length > 0) {
            setClaimForm({
                ...claimForm,
                pod: e[0]
            })
        }
    }
    const handleChangeAwbCodeOnBLur = (e) => {
        //console.log(e.target.value)
        axios.get(`https://airlabs.co/api/v9/airlines?iata_prefix=${e.target.value}&api_key=c447f4be-bd2b-4572-b0b9-7281d3b13eab`)
            .then((response) => {
                //console.log(response.data.response)
                if (response.data.response.length > 0) {
                    const airline = response.data.response[0]?.name || ""; // Nombre de la aerolínea
                    setClaimForm({
                        ...claimForm,
                        awb: e.target.value,
                        carrierName: airline,
                    })
                    setAwbNum(e.target.value)
                    setErrorAwb(false)
                }
                else{
                    setErrorAwb(true)
                }
            }).catch((error) => {
            //console.log(error);
        })
    }
    const getReceiverList = () => {
        axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/receiver`, {
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            }
        }).then((res) => {
            //console.log(res.data)
            setReceiverList(res.data)
        }).catch((error) => {
            //console.log(error)
        });
    }


    const getReceiver = (e) => {
        setClaimForm({
            ...claimForm,
            consigneeName: e
        })
        if (e.length > 2) {
            const city = receiverList.filter(receiver => receiver.description.toLowerCase().includes(e.toLowerCase()))
            setListForInput(city)
        }else{
            setListForInput([])
        }
    }
    const setReceiverName = (e) => {
        if (e[0]?.description !== undefined) {
            setClaimForm({
                ...claimForm,
                consigneeName: `${e[0].description}`
            })
        }
        //  sessionStorage.setItem("claim", JSON.stringify(claimForm))
    }
    useEffect(() => {
        getReceiverList()
    }, [])
    //console.log(claimForm.awb)
    return (
        <>
            <div>
                <Row style={{width:'100%', marginBottom:'1.5rem', justifyContent:"center"}}>
                    <Col sm={3} xs={12} className={styles.polPod} style={{position:'relative'}} >
                        <GiAirplaneDeparture style={{minWidth:'20px', fontSize:'2.5rem',color:'#5a68e9'}}/>
                       {/* <span className={styles.texto_inOut}>{traduccion.transport?.salida}</span>
                        <img src={loadingImg} alt="loading" className={styles.loading} style={{display:loadingPol ? "block" : "none", position:'absolute', top:-20, bottom:0,  left:30, width:50, height:100}}/> */}
                        <span className={styles.texto_inOut} style={{minWidth:'8ch', fontSize:'16px', alignSelf:'center'}}>{traduccion.transport?.salida2}</span>

                {stuff ?
                    <span className={styles.inicio_destino}>{stuff.data && stuff.data.puerto_carga}</span>:

                    <Typeahead
                        className={styles.typeahead2}
                        style={{marginLeft:'1rem', width:'27ch'}}
                        id="basic-typeahead"
                        defaultInputValue={claimForm?.pol}
                        labelKey={option => option}
                        onChange={handleChangePol}
                        // onInputChange={e=> handleChangePol(e)}
                        options={airports.map(airport => airport.company)}
                    />
                }

                </Col>
                    {width > 1449 &&
                        <Col sm={2} style={{textAlign: "end"}}>
                            <TbArrowNarrowLeft
                                style={{width: '2.2rem', transform: 'scaleX(3) scaleY(3)', color: '#b9bffe'}}/>
                            <TbLineDotted style={{
                                width: '4.2rem',
                                transform: 'scaleX(5) scaleY(5)',
                                color: '#b9bffe',
                                margin: '0 .3rem'
                            }}/>
                            <TbArrowNarrowRight
                                style={{width: '2.2rem', transform: 'scaleX(3) scaleY(3)', color: '#b9bffe'}}/>
                        </Col>
                    }
                {/* <span style={{marginLeft:"1rem"}}> */}
                <Col sm={5} className={styles.polPod} style={{position:'relative'}} >
                    <GiAirplaneArrival style={{minWidth:'20px', fontSize:'2.5rem',color:'#5a68e9'}}/>
                    {/* <span className={styles.texto_inOut}>{traduccion.transport?.llegada}</span> */}
                    {/* <img src={loadingImg} alt="loading" className={styles.loading} style={{display:loadingPod ? "block" : "none", position:'absolute', top:-20, bottom:0,  left:30, width:50, height:100}}/> */}
                    <span className={styles.texto_inOut} style={{minWidth:'8ch', fontSize:'16px', alignSelf:'center'}}>{traduccion.transport?.llegada2}</span>

                    {stuff ?
                        <span className={styles.inicio_destino}>{stuffing.data&& stuffing.data.puerto_destino}</span> :

                        <Typeahead
                            className={styles.typeahead2}
                            style={{marginLeft:'1rem', width:'27ch'}}
                            id="basic-typeahead"
                            defaultInputValue={claimForm?.pod}
                            labelKey={option => option}
                            onChange={handleChangePod}
                            //onInputChange={handleChangePol}
                            options={airports.map(airport => airport.company)}
                        />
                    }
                </Col>
                {/* </span> */}
            </Row>
                {/* </p> */}
                <Container className={styles.container}>

                    <Row className={styles.row}>
                        <Col>
                            <div className={styles.selectsBL} style={{justifyContent:"end"}}>
                                <div className="pe-2" style={{justifySelf:'end', marginRight:'1rem'}}>AWB</div>
                                <div style={{display:'flex'}}>
                                    <Form.Group>
                                        <Form.Control
                                            ref={refAWB1}
                                            style={{width: '8ch', display: 'inline'}}
                                            name="awb"
                                            onChange={handleChange}
                                            onBlur={(e) => handleChangeAwbCodeOnBLur(e)}
                                            value={claimForm.blAwb?.split("-")[0] || ""} // Dividir correctamente por "-"
                                        >
                                        </Form.Control>
                                        <Form.Control
                                            ref={refAWB2}
                                            style={{width: '17ch', display: 'inline'}}
                                            className="me-2"
                                            name="awb2"
                                            onChange={handleChange}
                                            value={claimForm.blAwb?.split("-")[1] || ""} // Dividir correctamente por "-"
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                            </div>
                            {errorAwb &&
                                <p style={{fontSize:11, margin:0, padding:0, color:"purple",textAlign:"end"}}>{traduccion?.others?.mustPutAValidCodeTogetAirline}</p>
                            }
                            <div className={styles.selects}>
                                <p className="pe-2">{traduccion?.maritime?.AgentedeCarga}</p>
                                <Form.Group className="me-3" controlId="ExporterName_A">
                                    <Form.Control
                                        type="text"
                                        placeholder={traduccion.maritime?.agentedeCarga}
                                        name="shipperName"
                                        onChange={handleChange}
                                        value={claimForm?.shipperName}
                                    />
                                </Form.Group>
                            </div>
                            <div className={styles.selects}>

                            <p className="pe-2">{traduccion?.claim?.exporter}
                                {/*<span style={{fontSize:"9px",margin:0}}>exporter_name</span>*/}
                            </p>
                            {(imRsk&& exportersName?.affiliatedCompanies?.length==0)?
                                <p>{claimForm?.companyName}</p>
                                :
                                imRsk?
                                    <Form.Select aria-label="Default select example" name="companyName"
                                                 onClick={handleChangeExporter}
                                                 onChange={handleChange}
                                                 value={claimForm?.companyId}>
                                        <option value={exportersName.id}>{exportersName.companyName}</option>
                                        {exportersName?.affiliatedCompanies?.map((item, index) => {
                                            //console.log(item)
                                            //console.log(exportersName)
                                            return <option key={index} value={item.id}>{item.companyName}</option>

                                        })}
                                    </Form.Select>
                                    :
                                    <div className={styles.selects}>
                                        <Form.Select aria-label="Default select example" name="companyName"
                                                     onClick={handleChangeExporter}
                                                     onChange={handleChange}
                                                     value={claimForm?.companyId}>
                                            <option value={exportersName.id}>{exportersName.companyName}</option>
                                            {exportersName?.affiliatedCompanies?.map((item, index) => {
                                                return <option key={index} value={item.id}>{item.companyName}</option>

                                            })}
                                        </Form.Select>
                                    </div>
                                // <Form.Group className="me-3" controlId="companyName">
                                //     <Form.Control type="text" placeholder="Nombre Exportador" name="companyName"
                                //                   onChange={handleChange}
                                //                   value={claimForm?.companyName}
                                //     />
                                // </Form.Group>
                            }
                            </div>
                            <div className={styles.selects}>
                                <p className="pe-2">{traduccion?.maritime?.recibidor}</p>
                                <Form.Group className="me-0" controlId="Recibidor">
                                    <Typeahead
                                        style={{position:"relative"}}
                                        className={styles.typeahead}
                                        id="basic-typeahead"
                                        defaultInputValue={claimForm?.consigneeName}
                                        labelKey={option => `${option.description}`}
                                        onChange={setReceiverName}
                                        onInputChange={e => getReceiver(e)}
                                        options={listForInput}
                                    />
                                </Form.Group>
                            </div>
                        </Col>
                        <Col>
                            <div className={styles.selects}>
                                <p className="pe-2" style={{maxWidth:"40%"}}>{traduccion?.others?.airline}
                                    <span style={{fontSize:"0px",margin:0}}>company_name</span>
                                </p>
                                    <Form.Group className="me-3" controlId="vessel_A">
                                        <Form.Control type="text" placeholder={traduccion?.others?.airline} name="carrierName"
                                                      onChange={handleChange}
                                                      value={claimForm.carrierName}/>
                                    </Form.Group>
                            </div>
                            <div className={styles.selects}>
                                <p className="pe-2">{traduccion?.mantendores?.fechaAWB}</p>
                                <Form.Group className="me-3" controlId="FechaAWB_A">
                                    <Form.Control type="date"  name="blAwbDate" onChange={handleChange} value={claimForm.blAwbDate?.substring(0,10)} /*value={stuffing.data&& stuffing.data.orden_embarque}*/ />
                                </Form.Group>
                            </div>

                            <div className={styles.selects}>
                                <p className="pe-2">ATA</p>
                                <Form.Group className="me-3" controlId="ATA_A">
                                    <Form.Control disabled={!imRsk} className={imRsk? styles.selects_input:styles.selects_input_disabled} type="date" name="ata" onChange={handleChange}  value={claimForm.ata?.substring(0,10)}  /*value={stuffing.data&& stuffing.data.orden_embarque}*/ />
                                </Form.Group>
                            </div>
                            <div className={styles.selects}>
                                <p className="pe-2">Timebar</p>
                                <Form.Group className="me-3" controlId="Timebar_A">
                                    <Form.Control disabled={!imRsk} className={imRsk? styles.selects_input:styles.selects_input_disabled} type="date"  name="timeBar" onChange={handleChange} value={claimForm.timeBar?.substring(0,10)}   /*value={stuffing.data&& stuffing.data.orden_embarque}*/ />
                                </Form.Group>
                            </div>

                        </Col>
                        <Col>
                            <div className={styles.monto_recuperado}>
                                <div>{traduccion.carga?.montoReclamado}</div>
                                <div className={styles.selects_m}>
                                    <div className="pe-2">{traduccion.maritime?.moneda}</div>

                                    <Form.Select  disabled={!imRsk} className={styles.moneda} name="currency" onChange={handleChange} value={claimForm?.cargo?.currency} >
                                        <option value="">---</option>
                                        {
                                            currency.map((currency) => (
                                                <option key={currency.id}
                                                        value={currency.id}>{currency?.description}</option>))
                                        }
                                    </Form.Select>
                                </div>
                                <div className={styles.selects_m}>
                                    <div className="pe-2">{traduccion.maritime?.monto}</div>
                                    <Form.Group className={styles.moneda} style={{borderRadius:6}} controlId="Monto">
                                        <Form.Control  disabled={!imRsk} type="text" placeholder={traduccion?.maritime?.monto} step=".01" name="reclaimedAmount"
                                                       onChange={handleChange}
                                                       onInput={onlyNumbers}
                                                       value={claimForm?.cargo?.reclaimedAmount}  /*value={stuffing.data&& stuffing.data.orden_embarque}*/ />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={styles.monto_recuperado}>
                                <div>{traduccion.maritime?.montoRecuperado}</div>
                                <div className={styles.selects_m}>
                                    <div className="pe-2">{traduccion.maritime?.moneda}</div>

                                    <Form.Select disabled={!imRsk} className={styles.moneda} name="currencyId" onChange={handleChange} value={claimForm?.currencyId} >
                                        <option value="">---</option>
                                        {
                                            currency.map((currency) => (
                                                <option key={currency.id}
                                                        value={currency.id}>{currency?.description}</option>))
                                        }
                                    </Form.Select>
                                </div>
                                <div className={styles.selects_m}>
                                    <div className="pe-2">{traduccion.maritime?.monto}</div>
                                    <Form.Group className={styles.moneda} style={{borderRadius:6}} controlId="Monto">
                                        <Form.Control disabled={!imRsk} type="text" placeholder={traduccion?.maritime?.monto} name="recoveredAmount"
                                                      onChange={handleChange}
                                                      onInput={onlyNumbers}
                                                      value={claimForm?.recoveredAmount}  /*value={stuffing.data&& stuffing.data.orden_embarque}*/ />
                                    </Form.Group>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </>    )
}
